import Vue from 'vue'
import VueRouter from 'vue-router' 

Vue.use(VueRouter)

const routes = [
 
 
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '@/components/Login')
  },

  {
    path: '/setup-wallet',
    name: 'SetupWallet',
    component: () => import(/* webpackChunkName: "setup-wallet" */ '@/components/SetupWallet')
  },
  {
    path: '/pending-signup',
    name: 'PendingSignup',
    component: () => import(/* webpackChunkName: "pending-signups" */ '@/view/PendingSignup')
  },
  {
    path: '/',
    name: 'Dashboard',
    component: () => import(/* webpackChunkName: "dashboard" */ '@/view/Dashboard')
  },
  {
    path: '/customer-application/:entityId',
    name: 'Application',
    component: () => import(/* webpackChunkName: "customer-application" */ '@/view/Application'),
    props: true
  },
  {
    path: '/customer-application-gaming/:entityId',
    name: 'ApplicationGaming',
    component: () => import(/* webpackChunkName: "customer-application-gaming" */ '@/view/ApplicationGaming'),
    props: true
  },
  {
    path: '/customer-application-list/:entityId',
    name: 'ApplicationTab',
    component: () => import(/* webpackChunkName: "customer-application" */ '@/view/ApplicationTab'),
    props: true
  },
  {
    path: '/customer-gaming-list/:entityId',
    name: 'ApplicationGamingTab',
    component: () => import(/* webpackChunkName: "customer-gaming-list" */ '@/view/ApplicationGamingTab'),
    props: true
  },
  
  {
    path: '/customer-letter-of-intent/:entityId',
    name: 'LetterIntent',
    component: () => import(/* webpackChunkName: "customer-application" */ '@/view/LetterIntent'),
    props: true
  },
  {
    path: '/customer-business-plan/:entityId',
    name: 'BusinessPlan',
    component: () => import(/* webpackChunkName: "customer-application" */ '@/view/BusinessPlan'),
    props: true
  },
  {
    path: '/customer-view-upload/:poidKey',
    name: 'ViewUpload',
    component: () => import(/* webpackChunkName: "customer-view-upload" */ '@/view/ViewUpload'),
    props: true
  },
  {
    path: '/customer-payments/:entityId',
    name: 'Payments',
    component: () => import(/* webpackChunkName: "customer-payments" */ '@/view/Payments'),
    props: true

  },
  {
    path: '/customer-compliance/:entityId',
    name: 'ComplianceReview',
    component: () => import(/* webpackChunkName: "customer-compliance" */ '@/view/ComplianceReview'),
    props: true

  },
  {
    path: '/compliance/',
    name: 'Compliance',
    component: () => import(/* webpackChunkName: "compliance" */ '@/view/Compliance'),
    props: true

  },
  {
    path: '/compliance-dashboard',
    name: 'ComplianceDashboard',
    component: () => import(/* webpackChunkName: "compliance" */ '@/view/ComplianceDashboard'),
    props: true

  },
  {
    path: '/refdata',
    name: 'RefData',
    component: () => import(/* webpackChunkName: "refdata" */ '@/view/RefData'),
    props: true

  },
  {
    path: '/namescreen/',
    name: 'Namescreen',
    component: () => import(/* webpackChunkName: "namescreen" */ '@/view/Namescreen'),
    props: true

  },
  {
    path: '/watchlist/',
    name: 'Watchlist',
    component: () => import(/* webpackChunkName: "watchlist" */ '@/view/Watchlist'),
    props: true

  },
  {
    path: '/add-to-watchlist/',
    name: 'AddToWatchlist',
    component: () => import(/* webpackChunkName: "add-to-watchlist" */ '@/view/AddToWatchlist'),
    props: true

  },
  {
    path: '/customer-profile/:entityId',
    name: 'Profiling',
    component: () => import(/* webpackChunkName: "customer-profile" */ '@/view/Profiling'),
    props: true

  },
  {
    path: '/customer-company/:entityId',
    name: 'Company',
    component: () => import(/* webpackChunkName: "customer-company" */ '@/view/Company'),
    props: true

  },

  {
    path: '/pending-alerts',
    name: 'PendingAlerts',
    component: () => import(/* webpackChunkName: "pending-alerts" */ '@/view/PendingAlerts')
  },
  {
    path: '/integration-logs',
    name: 'IntegrationLogs',
    component: () => import(/* webpackChunkName: "integration-logs" */ '@/view/IntegrationLogs')
  },
  {
    path: '/customer-search',
    name: 'CustomerSearch',
    component: () => import(/* webpackChunkName: "/" */ '@/view/CustomerSearch')
  },

 
  {
    path: '/customer-transactions/:entityId',
    name: 'Transactions',
    component: () => import(/* webpackChunkName: "customer-transactions" */ '@/view/Transactions'),
    props: true
  },
  {
    path: '/customer-activity/:entityId',
    name: 'ActivityLogs',
    component: () => import(/* webpackChunkName: "customer-activity" */ '@/view/ActivityLogs'),
    props: true
  },
  
  {
    path: '/customer-users/:entityId',
    name: 'Users',
    component: () => import(/* webpackChunkName: "customer-users" */ '@/view/Users'),
    props: true
  },
 
  {
    path: '/create-customers',
    name: 'CreateCustomer',
    component: () => import(/* webpackChunkName: "create-customer" */ '@/view/CreateCustomer')
  },
  {
    path: '/name-matches/:scanId',
    name: 'NamescreenMatches',
    component: () => import(/* webpackChunkName: "name-matches" */ '@/view/NamescreenMatches'),
    props: true
  },
  {
    path: '/admin-users',
    name: 'AdminUsers',
    component: () => import(/* webpackChunkName: "admin-users" */ '@/view/AdminUsers')
  },
  {
    path: '/admin-logs',
    name: 'AuditTrail',
    component: () => import(/* webpackChunkName: "admin-logs" */ '@/view/AuditTrail')
  },
  {
    path: '/transactions/',
    name: 'TransactionSearch',
    component: () => import(/* webpackChunkName: "transactions" */ '@/view/TransactionSearch'),
    props: true
  },
  {
    path: '/documents/',
    name: 'ManageDocuments',
    component: () => import(/* webpackChunkName: "documents" */ '@/view/ManageDocuments'),
    props: true
  },
  {
    path: '/aml/',
    name: 'AmlReport',
    component: () => import(/* webpackChunkName: "documents" */ '@/view/AmlReport'),
    props: true
  },




 
  
]

const router = new VueRouter({
  routes,
  // linkActiveClass: 'active'
  
})

export default router
