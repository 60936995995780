<template>
<div class="page-loader" v-if=!isLoaded>
    <div class="cube cube1"></div>
    <div class="cube cube2"></div>
    <div class="cube cube3"></div>
    <div class="cube cube4"></div>
    <div class="cube cube5"></div>
</div>
    
</template>
<script>
export default {
    data: () =>{
        return {
            isLoaded: false
        }
    },
    mounted(){
        document.onreadystatechange = () => {
            if(document.readyState == 'complete'){
                this.isLoaded = true;
            }
        }
        
    }
    
}
</script>
<style  scoped>
/* $colors: #862f7e, #7000a3, #681f6f, #56146686, #16116356; */
.page-loader{
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(2, 11, 54, 0.731);
    z-index: 999;
}
.cube1{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
    background: #862f7e;
}

.cube2{
    width: 40px;
    height: 40px;
    margin-right: 10px;
    background: #7000a3;
    border-radius: 50%;
}
.cube3{
    width: 40px;
    height: 40px;
    margin-right: 10px;
    background: #681f6f;
    border-radius: 50%;
}
.cube4{
    width: 40px;
    height: 40px;
    margin-right: 10px;
    background: #56146686;
    border-radius: 50%;
}
.cube5{
    width: 40px;
    height: 40px;
    margin-right: 10px;
    background: #16116356;
    border-radius: 50%;
}
.cube:first-child{
    animation: left 1s infinite;
}
.cube:last-child{
    animation: right 1s infinite .5s
}
@keyframes left{
    40%{
        transform: translateX(-60px);
    }
    50%{
        transform: translateX(0x);
    }
}

@keyframes right{
    40%{
        transform: translateX(60px);
    }
    50%{
        transform: translateX(0x);
    }
}


</style>