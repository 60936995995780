import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    errors: {
      changepinMsg: "",
      depositMsg: "",
      signupMsg: '',
      setupProfileMsg: '',
      profileMsg: '',
      loginMsg: '',
      wdrawCryptoMsg: '',
      msg: "",
      changepin: {
        currentPin: "",
        newPin1: "",
        newPin2: "",
      },
       deposit: {
        amount: "", 
      },
      wdraw: {
        amount: '',
        accountNumber: ''
      },
     
      signup: {
        emailOrMobile: '',
        show: false,
      },
      login: {
        loginId: '',
        pin: ''
      },
      newProfile: {
        firstName: '',
        lastName: '',
      },
      profile: {
        firstName: '',
        lastName: '',
      },
      wdrawCrypto: {
        outboundCryptoAddr: "",
        sourceAmount: "",
      }
    },

    show: {
      profile:{},
      corpDetails:{},
      
    },
    init: {
      FXWATCHLIST: false,
      FXADDCUSTOMER: false,
      FXADMIN: false,
      FXAPPROVERONE: false,
      FXAPPROVERTWO: false,
      FXCOMPLIANCE: false,
      FXCOMPLIANCECHECKER: false,
      FXCOMPLIANCEMAKER: false,
      FXCREATECUSTOMER: false,
      FXCUSTSEARCH: false,
      FXDOCUMENTS: false,
      FXNAMESCREEN: false,
      FXPENDINGSIGNUPS: false,
      FXSUPERADMIN: false,
      FXTRANSACTION: false,
      FXCUSTARCHIVE: false,
      FXCUSTVIEW: false,
      FXDELETE:false,
      FXCUSTEDIT:false,
      FXCUSTAPPLICATION:false,
      FXCUSTUSERS:false,
      FXCUSTTRANSACTIONS:false,
      FXCUSTISSUECERTIFICATE:false,
      FXCUSTPAYMENT:false,
      FXSIGN:false,
      FXPENDINGALERTS: false,
      address:{},
      addressInfo: {},
      assetInfo:{},
      customers:[],
      token:[],
      numberOfTokens:'',
      fund:{
        balance: 0,
        history: [],
      },
      viewPrivKey: {
        state: 'pin',
      },
      viewPrivKeyStep2: {
        state: 'confirm'
      },
      privClose: {
        state: ''
      },
      
      list:{
        customers:[],
        host:[],
        documents:[],
        documentData:[],
       
       
      },
      
      
    },
   
   
    

  },
  mutations: {

  },
  actions: {
  

  },
  modules: {
  }
})
