<template>
	<b-modal id="logout-modal" hide-footer hide-header hide-header-close size="sm" >
 
	   	<div class="logout-content">

	   		<div class="text-center text-dark m-3">
				<h4>Logout</h4>
			</div>
 
			 
			 <div class="label m-3 text-dark">
		   			<span class="text-dark">
			   			Are you sure you want to logout?
			   		</span>
		   		</div>

		   		<div class="width-100">
		   			
					<div class="input-group">
						 <button class="btn btn-main" v-on:click="confirmLogout"> Confirm </button>
                         <button class="btn text-dark btn-second" v-on:click="cancelLogout"> Cancel </button>
					</div>
		   		</div>
	   		
	   	</div>

	   	

	  </b-modal>
</template>

<script>
export default {
  name: 'Logout',
  data: function() {
        return  { 
        }
    },
  	computed: {
    	init() {
        	return this.$store.state.init;
    	},
    	show() {
	        return this.$store.state.show;
	    },
   	},
   	methods: {
   		cancelLogout: function(){
   		 	this.$bvModal.hide('logout-modal'); 
   		 },

   		confirmLogout: function(){
				const origin = localStorage.getItem("origin");
				// if(origin.toLowerCase() === "gwx") {
				// 	if(window.location.hostname == 'localhost') { 
				// 		window.location.href = "http://localhost:8080";
				// 	} else {
				// 		window.location.href = "https://secure.gameworx.io";
				// 	}
				// } else {

						
		   		
					
				// }
   		

   		this.axios.get('/ax/logout')
			        	.then((response) => { 
			        		window.location.href = '/#/login';
							location.reload();
								


			            
			        	}).catch((err) => {
			            	console.log(err)

			       	 	})
			}

   	},

}
</script>
 
<style scoped lang="scss">
	.text-dark{
		color: black !important;
	}
	#logout-modal {
		.logout-content {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			text-align: center;
		}
	}
	.btn-main{
	  background-image: linear-gradient(135deg,#007bff,#007bff 20%,#007bff 70%,#007bff)!important;
		background-size:0% 100%;
		background-repeat:no-repeat;
		transition:.4s;
		width:100%;
		border-radius: 10px;
		border: 1px #007bff solid !important;
		color: #007bff !important;
  }
  .btn-main:hover{
	  background-size:100% 100%;
    	color:white !important;
  }
  .btn-second{
	  	background-image: linear-gradient(135deg,#007bff,#007bff 20%,#007bff 70%,#007bff)!important;
		background-size:0% 100%;
		background-repeat:no-repeat;
		transition:.4s;
		width:100%;
		border-radius: 10px;
		padding:5px;
		border: 1px #007bff solid;
		color: white !important;
  }
   .btn-second:hover{
	  background-size:100% 100%;
    	color:white !important;
  }
  .btn-second {
    color: #007bff !important;
  }
	.label{
		color: black;
	}
</style>
