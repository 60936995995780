<template>
	<b-modal id="privatekey-modal" hide-footer  hide-header-close no-close-on-backdrop size="md" >

		<template #modal-header> 
      
			<div>
				<v-icon name="chevron-left" @click="initConfirm('close')" />
			</div>
			<h5>
				GET YOUR PRIVATE KEY
			</h5> 
			<div></div>

    </template>


		<div> 
				<div class="send-container" >
					<div id="loading" v-if="showLoading">
						      <div class="content">
						 
						        <v-icon name="spinner" pulse scale="3" />

						      </div>
						    </div>

					<form  v-if="init.viewPrivKeyStep2.state == 'confirm'">

						<h4 class="text-center">Your Private Key</h4>
						<div class="qr-container">
				   			<qrcode v-bind:value="init.address.issuerReference" v-bind:options="{ width: 200 }" class="qrCanvas"></qrcode>
				   		</div>
 
				   		<div class="input-group d-flex">
					      <input type="text" class="form-control" id="issuerReference" v-model="init.address.issuerReference" readonly>
					      <button class="btn btn-send" @click.prevent="copyKey" style="margin-top:0px; background-color:#ccc;">COPY</button>
					    </div>

						 <div class="flex  margin-top">
		    				<button  class="btn btn-main" type="button" v-on:click="initConfirm('leavenotif')">  
		    					<span>Close</span>	
		    				</button>
		    				 
		    			</div>


					</form>



					<form v-on:submit.prevent="onVerifySeed" v-if="init.viewPrivKeyStep2.state == 'verify'">

						<div class="alert alert-danger" v-if="errorMsg != ''"> {{ errorMsg }} </div>


	     				<div class="form-container">
		     				<label for="receipt">Seed 1:</label>
						  <input type="text"  v-model="seed.one" class="form-control" >
						  <span class="error" v-if="errors.one != ''"> {{ errors.one }} </span> 
	     				</div>

	     				<div class="form-container">
		     				<label for="receipt">Seed 2:</label>
						  <input type="text"  v-model="seed.two" class="form-control" >
						  <span class="error" v-if="errors.two != ''"> {{ errors.two }} </span> 
	     				</div>

	     				<div class="form-container">
		     				<label for="receipt">Seed 3:</label>
						  <input type="text"  v-model="seed.three" class="form-control" >
						  <span class="error" v-if="errors.three != ''"> {{ errors.three }} </span> 
	     				</div>

	     				<div class="form-container">
		     				<label for="receipt">Seed 4:</label>
						  <input type="text"  v-model="seed.four" class="form-control" >
						  <span class="error" v-if="errors.four != ''"> {{ errors.four }} </span> 
	     				</div>

	     				<div class="form-container">
		     				<label for="receipt">Seed 5:</label>
						  <input type="text"  v-model="seed.five" class="form-control" >
						  <span class="error" v-if="errors.five != ''"> {{ errors.five }} </span> 
	     				</div>

	     				<!-- <div class="form-container">
		     				<label for="receipt">Seed 6:</label>
						  <input type="text"  v-model="seed.six" class="form-control" >
						  <span class="error" v-if="errors.six != ''"> {{ errors.six }} </span> 
	     				</div> -->
	     			
					   
					  
					  <div class="flex  margin-top">
	    				<button  class="btn btn-main" type="submit">  
	    					<span>Verify</span>	
	    				</button>
	    				 
	    			</div>
					</form>

						<form  v-if="init.viewPrivKeyStep2.state == 'request'">
								


						<h4 class="text-center">Wallet Seed</h4>
						<div class="text-center">
							<p>Please save these 5 codes with 7 Alpha numeric characters on paper (order is important). This seed will allow you to recover your wallet in case of computer failure.</p>

							<p>WARNING:<br>
						- Never disclose your seed. <br>
						- Never type it on a website. <br>
						- Do not store it electronically. <br>
						- You can only access this once. <br>


						<p>Your wallet generation seed are:</p>
						</div>



						<div class="seed-request-wrapper">
							<div class="seed-request" v-for="(seed, index) in seedRequest" :key="(seed, index)"> 
								<div class="speed-index"> {{ index+1 }} </div>
							 {{ seed }} 
							</div>
						</div>

						 <div class="flex  margin-top">
		    				<button  class="btn btn-main" type="button" v-on:click="onConfirm">  
		    					<span>Confirm</span>	
		    				</button>
		    				 
		    			</div>


					</form>

 
				</div> 
		</div>
		<LeaveKey/>
		<LeftKey :func="leaveFunction"/>
	</b-modal>
</template>

<script>

import qs from 'querystring'  
import LeaveKey from '@/components/modal/ConfirmLeavePrivateKey.vue'
import LeftKey from '@/components/modal/ConfirmedLeavePrivateKey.vue'
export default {
  name: 'PrivateKey',
  components: {
	  LeaveKey,
	  LeftKey,
  },
  data: function() {
      return  {
      	seed: {
      		one: "",
      		two: "",
      		three: "",
      		four: "",
      		five: "",
      		six: "",
      	},
      	showError: false,
      	errorMsg: "",
      	errors: {
      		one: "",
      		two: "",
      		three: "",
      		four: "",
      		five: "",
      		six: "",
      	},
      	state: 'verify',
      	seedRequest: [],
      	seedShown: false,
      	showLoading: true,  
      	seedPhraseErr: "",
      	privateKeyStatus: "",
		leaveFunction: "",
      }
    },
  	computed: {
    	init() {
        	return this.$store.state.init;
    	},
    	
   	},
   	methods: {
   		 copyKey() {
        let copyText = document.getElementById("issuerReference");
          copyText.select();
          copyText.setSelectionRange(0, 99999); 

          document.execCommand("copy");
      }, 

   		initConfirm: function(func){ 

   			this.init.privClose.state = '';
   			console.log(func);

   			switch(func) {
   				case 'close':

   					this.init.privClose.state = 'closemodal';
   					this.$bvModal.show('leave-key-modal'); 
   					break;

   				case 'confirm':
   					this.init.privClose.state = 'showprivkey';
   					this.$bvModal.show('leave-key-modal'); 
   					break;

   					case 'leavenotif':
   						this.leaveFunction = func;
   						//console.log()
   								if(this.privateKeyStatus == 'init') {
				   					this.$bvModal.show('left-key-modal'); 
				   				} else {
				   					this.closeReload();
				   				}
   					break;

   			}

   		// 	if(func == 'leavenotif') {
   		// 		this.leaveFunction = func

   		// 		if(this.privateKeyStatus == 'init') {
   		// 			this.$bvModal.show('leave-key-modal'); 
   		// 		} else {
   		// 			this.closeReload();
   		// 		}

					
   		// 	} else if(func == 'confirm') {
   		// 		this.leaveFunction = 'leavenotif';
   		// 			this.$bvModal.show('leave-key-modal'); 
   		// 	} else {
   					
   				
					// if(this.state == 'request') {
						
						 
					//  this.state = 'confirm';
						  
					// }  else {
					// 	this.close();
					// 	this.$emit('close')
					// }
   		// 	}
				


	   		},

		onConfirm: function(){
   			this.init.viewPrivKeyStep2.state = 'verify';
   			localStorage.removeItem("seed");
   		},


   		close: function(){
				this.$bvModal.hide('privatekey-modal'); 
   		},

		   closeReload: function(){
				window.location.reload(
					
				)
   		},
   		 initSeedPhrase: function() {

   		 		this.init.viewPrivKeyStep2.state = 'request';
   		 		this.showLoading = true;
              	this.seed = {
								      		one: "",
								      		two: "",
								      		three: "",
								      		four: "",
								      		five: "",
								      		six: "",
								      	};

								    this.errors = {
								      		one: "",
								      		two: "",
								      		three: "",
								      		four: "",
								      		five: "",
								      		six: "",
								      	};
				        const options = {
				          method: 'GET',
				          headers: { 'content-type': 'application/x-www-form-urlencoded' },
				          url: '/cx/seedphrase/init',
				        };

        this.axios(options)
          .then((response) => { 
           		//console.log(response)
           		this.init.viewPrivKeyStep2.state = 'verify';
           		this.showLoading = false;

            }).catch((err) => {
                
             // console.log(err.response.data)
              this.showLoading = true;

              this.seedPhraseErr = err.response.data.msgCode;
              if(err.response.data.msgCode == 'NO.SEED') {

             		this.privateKeyStatus = 'init';
              	
              	
              }

            })
      },

       requestSeedPhrase: function() {

        const options = {
          method: 'GET',
          headers: { 'content-type': 'application/x-www-form-urlencoded' },
          url: '/cx/private/seedphrase',
        };

        this.axios(options)
          .then((response) => { 
          	this.showLoading = false;
          	this.seedRequest =  response.data; 

          	localStorage.setItem("seed",JSON.stringify(this.seedRequest));
          
		          	if(response.data.length > 0) {
		          		this.seedShown = true;
		          	}

		          	this.showLoading = false;

            }).catch((err) => {
                
              console.log(err)
 
              this.seedRequest = JSON.parse(localStorage.getItem("seed"));


            })
      },

      onVerifySeed: function(){
      		let hasError = false;
					
					if(this.seed.one == '' || this.seed.one == undefined || this.seed.one == null) {
						this.errors.one = 'Seed one is required.';
						hasError = true;
					}

					if(this.seed.two == '' || this.seed.two == undefined || this.seed.two == null) {
						this.errors.two = 'Seed two is required.';
						hasError = true;
					}

					if(this.seed.three == '' || this.seed.three == undefined || this.seed.three == null) {
						this.errors.three = 'Seed three is required.';
						hasError = true;
					}

					if(this.seed.four == '' || this.seed.four == undefined || this.seed.four == null) {
						this.errors.four = 'Seed four is required.';
						hasError = true;
					}

					if(this.seed.five == '' || this.seed.five == undefined || this.seed.five == null) {
						this.errors.five = 'Seed five is required.';
						hasError = true;
					}
 

					if(!hasError) {
						this.errorMsg = '';
						this.errors= {
		      		one: "",
		      		two: "",
		      		three: "",
		      		four: "",
		      		five: "",
		      		six: "",
		      	};
						const params = {
		          seedPhraseOne: this.seed.one,
		          seedPhraseTwo: this.seed.two,
		          seedPhraseThree: this.seed.three,
		          seedPhraseFour: this.seed.four,
		          seedPhraseFive: this.seed.five
		        }

		        const data = Object.keys(params)
		          .map((key) => `${key}=${encodeURIComponent(params[key])}`)
		          .join('&');
		   

		        const options = {
		          method: 'POST',
		          headers: { 'content-type': 'application/x-www-form-urlencoded' },
		          data,
		          url: '/cx/seedphrase/verify',
		        };

		        this.axios(options)
		          .then((response) => {
		          	localStorage.removeItem("seed");
		              this.init.viewPrivKeyStep2.state = 'confirm';
		            }).catch((err) => {
		                this.showError = true;
		                if (err.response.data) { 

		                  if(err.response.data.fieldErrors) {

		                    err.response.data.fieldErrors.forEach((error) => {

		                      switch (error.field) {
 														
		                      	case 'seedPhraseOne':
		                      		this.errors.one = error.defaultMessage;
		                      		break;

		                      	case 'seedPhraseTwo':
		                      		this.errors.two = error.defaultMessage;
		                      		break;

		                      	case 'seedPhraseThree':
		                      		this.errors.three = error.defaultMessage;
		                      		break;

		                      	case 'seedPhraseFour':
		                      		this.errors.four = error.defaultMessage;
		                      		break;

		                      	case 'seedPhraseFive':
		                      		this.errors.five = error.defaultMessage;
		                      		break;

		                      	case 'seedPhraseSix':
		                      		this.errors.six = error.defaultMessage;
		                      		break;
 
		                      }
		                    });

		                  }  else {

		                    this.errorMsg = err.response.data.msgText;
		                  }
		                }
		                 
		          })
					}
      }

   	}, 
 		mounted(){
   		 this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
	    	if(modalId == 'privatekey-modal') {
	    		this.initSeedPhrase();



	    	}
	    })


   		  	
   		  setTimeout(() => {
	    			
   		  		if(this.seedPhraseErr == 'NO.SEED') {
   		  			this.requestSeedPhrase();
   		  		}
        		
        	}, 2000)

   		
 		},
 		watch: {

      'seed.one': function(newValue, oldValue) {
          if(newValue !== oldValue) {  

            this.errors.one = ""; 
          }
      },

      'seed.two': function(newValue, oldValue) {
          if(newValue !== oldValue) {  

            this.errors.two = ""; 
          }
      },

      'seed.three': function(newValue, oldValue) {
          if(newValue !== oldValue) {  

            this.errors.three = ""; 
          }
      },

      'seed.four': function(newValue, oldValue) {
          if(newValue !== oldValue) {  

            this.errors.four = ""; 
          }
      },

      'seed.five': function(newValue, oldValue) {
          if(newValue !== oldValue) {  

            this.errors.five = ""; 
          }
      },
    }

}
</script>
 
<style scoped lang="scss">
 #loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000; 
  color: #5f57a5;
  display: -webkit-box;
  /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box;
  /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox;
  /* TWEENER - IE 10 */
  display: -webkit-flex;
  /* NEW - Chrome */
  display: flex;
  /* NEW, Spec - Opera 12.1, Firefox 20+ */
  -webkit-justify-content: center;
  -moz-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
      background: #210b5f;
}
#loading .content {
  display: inline-block;
  text-align: center;
  color: #141414;
}
#loading .content img {
  display: block;
  margin-bottom: 0.5em;
}
#loading .content .icon {
  font-size: 3em;
  fill: #fff;
}
.qr-container {
	display: flex;
	justify-content: center;
	align-items: center;
}
	  .send-container { 
	   
	    border-radius: 6px;
	    flex-direction: column;
	    position: relative;
	    background: #FFF; 
	    color: #000;
  }

   .error {
  	color: red;
  }

  button {
  	margin-top:1em;
  }

  .seed-request-wrapper {
  	display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    .seed-request {

 		   width: 33.33%;
 		   display: flex;
 		       margin-top: 1em;


 		   .speed-index {
	 		       background: #DB8D20;
			    color: #FFF;
			    height: 25px;
			    width: 25px;
			    border-radius: 50%;
			        margin-right: 0.5em;
			    display: flex;
			    justify-content: center;
 		   }
    }
  }
  label, .form-control{
	  color: black;
  }
		.btn-main{
	  background-image: linear-gradient(135deg,#007bff,#007bff 20%,#007bff 70%,#007bff)!important;
		background-size:0% 100%;
		background-repeat:no-repeat;
		transition:.4s;
		width:100%;
		border-radius: 10px;
		padding:5px;
		border: 1px #007bff solid;
		color:#007bff !important;
  }
  .btn-main:hover{
	  background-size:100% 100%;
    	color:white !important;
  }
  
</style>
