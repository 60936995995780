import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios' 
import Chartist from "chartist";
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'vue-awesome/icons'
import 'vue-awesome/icons/spinner'
import 'vue-awesome/icons/tag'
import Icon from 'vue-awesome/components/Icon'
import VueCarousel from 'vue-carousel';
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { BVToastPlugin } from "bootstrap-vue";
import CanvasJSChart from '@canvasjs/vue-charts'
import VueCharts from "vue-charts";
import VueZoomer from 'vue-zoomer'

Vue.use(VueZoomer);
Vue.use(VueCarousel);
Vue.use(BVToastPlugin);
// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'


Vue.config.productionTip = false
Vue.use(VueAxios, axios)
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
Vue.component('icon', Icon) 
Vue.component('v-icon', Icon)
Vue.component("font-awesome-icon", FontAwesomeIcon)
Vue.use(CanvasJSChart)
Vue.use(VueCharts);



axios.defaults.headers.common['Content-Type'] = 'application/x-www-form-urlencoded; charset=UTF-8'; 
axios.defaults.headers.common['vmiadmin-authz'] = localStorage.getItem("tokenId");

new Vue({
  router,
  store,
  data: {
    Chartist: Chartist,
  },
  render: function (h) { return h(App) }
}).$mount('#app')
